import React from "react"
import Link from '../utils/link'

import SEO from "../components/seo"
import Banner from "../assets/images/banner.jpg"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <section className='scrolling-banner'>
      <img className='scrolling-banner__image' src={Banner} alt={'404 Error'} />
      <div className='scrolling-banner__title'>
      	<div className='scrolling-banner__error'>
	        <h1>404 Error</h1>
	        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
				  <Link to='/' className='btn'>Back home</Link>
			  </div>
      </div>
    </section>
  </>
)

export default NotFoundPage
